import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from 'components/Layout'
import LeadCTAButton from 'components/LeadCTAButton'
import { Text, Box } from 'components'

import PageSnippets from 'screens/Home/components/PageSnippets'
import Testimonials from 'screens/Home/components/Testimonials'
import Map from 'screens/Home/components/Map'
import Partner from 'screens/Home/components/Partner'
import ContactFullWidth from 'screens/Home/components/ContactFullWidth'
import PageTitleBanner from 'screens/Home/components/PageTitleBanner'
import Flexibility from 'screens/Home/components/Flexibility'
import Flow from 'screens/Home/components/Flow'
import UserStory from 'screens/Home/components/UserStory'
import Blog from 'screens/Home/components/Blog'

import { useTranslate } from 'context/location'

const Page = () => {
  const translate = useTranslate()

  const {
    featuredArticles: { articles: featuredArticles },
    contactFullWidthImage,
    salesImage,
    bambusHouseImage,
    userStoryImage,
  } = useStaticQuery(
    graphql`
      query {
        featuredArticles: contentfulFeaturedArticlesLayout(
          node_locale: { eq: "de" }
          slug: { eq: "beratung" }
        ) {
          articles {
            ...Article
          }
        }
        contactFullWidthImage: file(
          relativePath: { eq: "business-man-1.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        salesImage: file(
          relativePath: { eq: "sales-photos/sales_team-1.jpeg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bambusHouseImage: file(relativePath: { eq: "bambus-haus.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 560, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        userStoryImage: file(relativePath: { eq: "kundin-main-cropped.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Layout
      seo={{
        title:
          'Top unverbindliche Beratung für Immobilienbesitzer zu den Themen Teilverkauf, Leibrente und Beleihung',
        description:
          'Bambus Immobilien ist Ihr Experte, wenn es um die finanziellen Möglichkeiten für Ihr Haus oder Ihre Wohnung geht. Wir beraten Sie zu allen Optionen und machen Ihnen bei Bedarf ein persönliches Angebot zum Immobilien Teilverkauf.',
      }}
    >
      <PageTitleBanner
        heading="Bei uns wird Beratung groß geschrieben"
        subheading="Wobei dürfen wir Sie unterstützen?"
        wordsToRotate={[
          'Wünsche',
          'Ziele',
          'Pläne',
          'Träume',
          'Ideen',
          'Vorhaben',
        ]}
        rotatingWordsWidth={200}
      />
      <Flexibility
        items={[
          {
            title: 'Individuelle Lösungen',
            description:
              'Ob Wohn- oder Ferienhaus, ob alleinstehend oder im Familienverbund – wir haben die richtige Lösung für Sie! Für mehr finanzielle Flexibilität & Liquidität',
          },
          {
            title: 'Persönliches Gespräch',
            description:
              'Vereinbaren Sie Ihren telefonischen Wunschtermin mit unseren Experten! Sprechen wir über Ihren Bedarf',
          },
          {
            title: 'Vollumfängliche Beratung',
            description:
              'Unsere Mitarbeiter setzen sich im Detail mit Ihrer Situation auseinander und beraten Sie gerne zu den für Sie passenden Lösungen',
          },
          {
            title: 'Experten Knowhow',
            description:
              'Profitieren Sie von unserer langjährigen Erfahrung bei der Liquiditätsbeschaffung für Immobilienbesitzer',
          },
        ]}
        ctaButtonID="Beratung--Flexibility"
      />
      <Flow
        title="So einfach funktioniert's"
        // items={[
        //   {
        //     title: 'Kontaktformular abschicken',
        //     description: 'Für Ihr persönliches Beratungsgespräch',
        //   },
        //   {
        //     title: 'Erzählen Sie uns von Ihrem Vorhaben',
        //     description: 'Wir finden die passende Lösung',
        //   },
        //   {
        //     title: 'Unverbindliches Angebot erhalten',
        //     description: 'Online Antragsformular ausfüllen',
        //   },
        //   {
        //     title: 'Sie wollen sich Ihr Angebot sichern?',
        //     description: 'Starten Sie den Dokumentenupload',
        //   },
        //   {
        //     title: 'Die Bank sendet Ihnen Ihre Unterlagen',
        //     description: 'Unterfertigen Sie diese beim Notar',
        //   },
        //   {
        //     title: ' Ihr Geld steht Ihnen zur Verfügung',
        //     description: 'Erfüllen Sie sich Ihren Wunsch',
        //   },
        // ]}
        items={[]}
        hideAboveCTAText
        aboveCTAElement={
          <Box pt={5} pb={5}>
            <Text fontWeight="bold" fontSize={4}>
              Sie möchten mehr Information zur Kapitalbeschaffung?
            </Text>
            <Text mt={4} fontSize={3}>
              Wir schicken Ihnen gerne per Mail mehr Information zum
              Kapitalbeschaffungsprozess bei Bambus. In unserem Infoblatt finden
              Sie eine genau beschreibung des Prozesses bei Bambus.
            </Text>
          </Box>
        }
        ctaElement={
          <LeadCTAButton
            variant="v2Main1"
            width={['100%', 'auto']}
            ctaButtonID="Beratung--Flow"
            leadModalType="reduced"
            signupReason="information"
          >
            Infoblatt beantragen
          </LeadCTAButton>
        }
      />
      <ContactFullWidth
        title="Jetzt Vorteile nutzen"
        subheading="Was darf es für Sie sein?"
        email="beratung@bambus.io"
        phoneNumber={translate({
          DE: '+49 30 837 960 40',
          AT: '+43 1 435 0123',
          _: '+43 1 435 0123',
        })}
        gatsbyFluidImage={contactFullWidthImage.childImageSharp.fluid}
        imagePosition={[
          '25px 0px',
          '100px 0px',
          '200px 0px',
          '240px 0px',
          '500px 40%',
        ]}
        imageTransform={['scale(1.2)', 'scale(1)', 'scale(1)']}
        ctaBlueButtonID="Beratung--ContactFullWidth-Blue"
        ctaPinkButtonID="Beratung--ContactFullWidth-Pink"
        leadModalTypeBlueButton="teilverkauf_callback"
        leadModalTypePinkButton="teilverkauf"
      />
      <PageSnippets
        title="Sie wollen mehr über Bambus wissen?"
        snippets={[
          {
            id: 1,
            title: 'Unsere Werte',
            description: 'Einfach, sicher & transparent',
            link: '/werte',
            gatsbyFluidImage: salesImage.childImageSharp.fluid,
            imagePosition: 'center 65%',
          },
          {
            id: 2,
            title: 'Über Bambus',
            description: 'Unsere Mission - unsere Vision - unsere Lösungen',
            link: '/team',
            gatsbyFluidImage: bambusHouseImage.childImageSharp.fluid,
          },
        ]}
      />
      <Blog
        title="Tauchen Sie in unseren Ratgeber ein"
        articles={featuredArticles}
      />
      <Testimonials
        showCTA
        userStory={
          <UserStory
            description="Ich wusste gar nichts von der Möglichkeit, mein Haus anteilig zu verkaufen und darin wohnen zu bleiben. Ich kann jetzt schon viel früher als geplant meine Renovierungen umsetzen und mein Dach mit Solarpanelen ausstatten. Zum Glück habe ich für ein Beratungsgespräch zum Hörer gegriffen."
            gatsbyFluidImage={userStoryImage.childImageSharp.fluid}
          />
        }
        ctaButtonID="Beratung--Testimonials"
      />
      <Map />
    </Layout>
  )
}
export default Page
